// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-1-inch-exchange-clone-script-js": () => import("./../../../src/pages/1inch-exchange-clone-script.js" /* webpackChunkName: "component---src-pages-1-inch-exchange-clone-script-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-asset-tokenization-platform-development-company-js": () => import("./../../../src/pages/asset-tokenization-platform-development-company.js" /* webpackChunkName: "component---src-pages-asset-tokenization-platform-development-company-js" */),
  "component---src-pages-bakeryswap-clone-script-js": () => import("./../../../src/pages/bakeryswap-clone-script.js" /* webpackChunkName: "component---src-pages-bakeryswap-clone-script-js" */),
  "component---src-pages-bep-20-token-development-js": () => import("./../../../src/pages/bep-20-token-development.js" /* webpackChunkName: "component---src-pages-bep-20-token-development-js" */),
  "component---src-pages-binance-clone-script-js": () => import("./../../../src/pages/binance-clone-script.js" /* webpackChunkName: "component---src-pages-binance-clone-script-js" */),
  "component---src-pages-blockchain-development-company-js": () => import("./../../../src/pages/blockchain-development-company.js" /* webpackChunkName: "component---src-pages-blockchain-development-company-js" */),
  "component---src-pages-centralized-exchange-development-company-js": () => import("./../../../src/pages/centralized-exchange-development-company.js" /* webpackChunkName: "component---src-pages-centralized-exchange-development-company-js" */),
  "component---src-pages-coinbase-clone-script-js": () => import("./../../../src/pages/coinbase-clone-script.js" /* webpackChunkName: "component---src-pages-coinbase-clone-script-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-crypto-launchpad-development-company-js": () => import("./../../../src/pages/crypto-launchpad-development-company.js" /* webpackChunkName: "component---src-pages-crypto-launchpad-development-company-js" */),
  "component---src-pages-crypto-token-development-js": () => import("./../../../src/pages/crypto-token-development.js" /* webpackChunkName: "component---src-pages-crypto-token-development-js" */),
  "component---src-pages-cryptocurrency-app-development-company-js": () => import("./../../../src/pages/cryptocurrency-app-development-company.js" /* webpackChunkName: "component---src-pages-cryptocurrency-app-development-company-js" */),
  "component---src-pages-cryptocurrency-development-company-js": () => import("./../../../src/pages/cryptocurrency-development-company.js" /* webpackChunkName: "component---src-pages-cryptocurrency-development-company-js" */),
  "component---src-pages-cryptocurrency-exchange-development-company-js": () => import("./../../../src/pages/cryptocurrency-exchange-development-company.js" /* webpackChunkName: "component---src-pages-cryptocurrency-exchange-development-company-js" */),
  "component---src-pages-cryptocurrency-payment-gateway-js": () => import("./../../../src/pages/cryptocurrency-payment-gateway.js" /* webpackChunkName: "component---src-pages-cryptocurrency-payment-gateway-js" */),
  "component---src-pages-cryptocurrency-wallet-development-company-js": () => import("./../../../src/pages/cryptocurrency-wallet-development-company.js" /* webpackChunkName: "component---src-pages-cryptocurrency-wallet-development-company-js" */),
  "component---src-pages-dao-development-company-js": () => import("./../../../src/pages/dao-development-company.js" /* webpackChunkName: "component---src-pages-dao-development-company-js" */),
  "component---src-pages-dapp-development-company-js": () => import("./../../../src/pages/dapp-development-company.js" /* webpackChunkName: "component---src-pages-dapp-development-company-js" */),
  "component---src-pages-decentralized-exchange-development-company-js": () => import("./../../../src/pages/decentralized-exchange-development-company.js" /* webpackChunkName: "component---src-pages-decentralized-exchange-development-company-js" */),
  "component---src-pages-defi-crowdfunding-platform-development-js": () => import("./../../../src/pages/defi-crowdfunding-platform-development.js" /* webpackChunkName: "component---src-pages-defi-crowdfunding-platform-development-js" */),
  "component---src-pages-defi-development-company-js": () => import("./../../../src/pages/defi-development-company.js" /* webpackChunkName: "component---src-pages-defi-development-company-js" */),
  "component---src-pages-defi-dex-aggregator-development-js": () => import("./../../../src/pages/defi-dex-aggregator-development.js" /* webpackChunkName: "component---src-pages-defi-dex-aggregator-development-js" */),
  "component---src-pages-defi-staking-platform-development-js": () => import("./../../../src/pages/defi-staking-platform-development.js" /* webpackChunkName: "component---src-pages-defi-staking-platform-development-js" */),
  "component---src-pages-defi-wallet-development-company-js": () => import("./../../../src/pages/defi-wallet-development-company.js" /* webpackChunkName: "component---src-pages-defi-wallet-development-company-js" */),
  "component---src-pages-enjin-clone-script-js": () => import("./../../../src/pages/enjin-clone-script.js" /* webpackChunkName: "component---src-pages-enjin-clone-script-js" */),
  "component---src-pages-ethereum-smart-contract-development-js": () => import("./../../../src/pages/ethereum-smart-contract-development.js" /* webpackChunkName: "component---src-pages-ethereum-smart-contract-development-js" */),
  "component---src-pages-ethereum-token-development-js": () => import("./../../../src/pages/ethereum-token-development.js" /* webpackChunkName: "component---src-pages-ethereum-token-development-js" */),
  "component---src-pages-fintech-software-development-company-js": () => import("./../../../src/pages/fintech-software-development-company.js" /* webpackChunkName: "component---src-pages-fintech-software-development-company-js" */),
  "component---src-pages-foundation-clone-script-js": () => import("./../../../src/pages/foundation-clone-script.js" /* webpackChunkName: "component---src-pages-foundation-clone-script-js" */),
  "component---src-pages-ico-consulting-services-js": () => import("./../../../src/pages/ico-consulting-services.js" /* webpackChunkName: "component---src-pages-ico-consulting-services-js" */),
  "component---src-pages-ico-development-company-js": () => import("./../../../src/pages/ico-development-company.js" /* webpackChunkName: "component---src-pages-ico-development-company-js" */),
  "component---src-pages-ido-development-company-js": () => import("./../../../src/pages/ido-development-company.js" /* webpackChunkName: "component---src-pages-ido-development-company-js" */),
  "component---src-pages-ieo-development-company-js": () => import("./../../../src/pages/ieo-development-company.js" /* webpackChunkName: "component---src-pages-ieo-development-company-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-localbitcoins-clone-script-js": () => import("./../../../src/pages/localbitcoins-clone-script.js" /* webpackChunkName: "component---src-pages-localbitcoins-clone-script-js" */),
  "component---src-pages-mvp-development-js": () => import("./../../../src/pages/mvp-development.js" /* webpackChunkName: "component---src-pages-mvp-development-js" */),
  "component---src-pages-nft-exchange-development-js": () => import("./../../../src/pages/nft-exchange-development.js" /* webpackChunkName: "component---src-pages-nft-exchange-development-js" */),
  "component---src-pages-nft-launchpad-development-company-js": () => import("./../../../src/pages/nft-launchpad-development-company.js" /* webpackChunkName: "component---src-pages-nft-launchpad-development-company-js" */),
  "component---src-pages-nft-lending-platform-development-js": () => import("./../../../src/pages/nft-lending-platform-development.js" /* webpackChunkName: "component---src-pages-nft-lending-platform-development-js" */),
  "component---src-pages-nft-marketplace-development-js": () => import("./../../../src/pages/nft-marketplace-development.js" /* webpackChunkName: "component---src-pages-nft-marketplace-development-js" */),
  "component---src-pages-nft-minting-platform-development-js": () => import("./../../../src/pages/nft-minting-platform-development.js" /* webpackChunkName: "component---src-pages-nft-minting-platform-development-js" */),
  "component---src-pages-nft-staking-platform-development-js": () => import("./../../../src/pages/nft-staking-platform-development.js" /* webpackChunkName: "component---src-pages-nft-staking-platform-development-js" */),
  "component---src-pages-nft-token-development-js": () => import("./../../../src/pages/nft-token-development.js" /* webpackChunkName: "component---src-pages-nft-token-development-js" */),
  "component---src-pages-nft-wallet-development-company-js": () => import("./../../../src/pages/nft-wallet-development-company.js" /* webpackChunkName: "component---src-pages-nft-wallet-development-company-js" */),
  "component---src-pages-opensea-clone-script-js": () => import("./../../../src/pages/opensea-clone-script.js" /* webpackChunkName: "component---src-pages-opensea-clone-script-js" */),
  "component---src-pages-otc-crypto-exchange-development-js": () => import("./../../../src/pages/otc-crypto-exchange-development.js" /* webpackChunkName: "component---src-pages-otc-crypto-exchange-development-js" */),
  "component---src-pages-p-2-p-crypto-exchange-development-js": () => import("./../../../src/pages/p2p-crypto-exchange-development.js" /* webpackChunkName: "component---src-pages-p-2-p-crypto-exchange-development-js" */),
  "component---src-pages-pancakeswap-clone-script-js": () => import("./../../../src/pages/pancakeswap-clone-script.js" /* webpackChunkName: "component---src-pages-pancakeswap-clone-script-js" */),
  "component---src-pages-paxful-clone-script-js": () => import("./../../../src/pages/paxful-clone-script.js" /* webpackChunkName: "component---src-pages-paxful-clone-script-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-rarible-clone-script-js": () => import("./../../../src/pages/rarible-clone-script.js" /* webpackChunkName: "component---src-pages-rarible-clone-script-js" */),
  "component---src-pages-remitano-clone-script-js": () => import("./../../../src/pages/remitano-clone-script.js" /* webpackChunkName: "component---src-pages-remitano-clone-script-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-smart-contract-development-company-js": () => import("./../../../src/pages/smart-contract-development-company.js" /* webpackChunkName: "component---src-pages-smart-contract-development-company-js" */),
  "component---src-pages-sto-development-company-js": () => import("./../../../src/pages/sto-development-company.js" /* webpackChunkName: "component---src-pages-sto-development-company-js" */),
  "component---src-pages-superrare-clone-script-js": () => import("./../../../src/pages/superrare-clone-script.js" /* webpackChunkName: "component---src-pages-superrare-clone-script-js" */),
  "component---src-pages-sushiswap-clone-script-js": () => import("./../../../src/pages/sushiswap-clone-script.js" /* webpackChunkName: "component---src-pages-sushiswap-clone-script-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-tron-token-development-js": () => import("./../../../src/pages/tron-token-development.js" /* webpackChunkName: "component---src-pages-tron-token-development-js" */),
  "component---src-pages-uniswap-clone-script-js": () => import("./../../../src/pages/uniswap-clone-script.js" /* webpackChunkName: "component---src-pages-uniswap-clone-script-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-wazirx-clone-script-js": () => import("./../../../src/pages/wazirx-clone-script.js" /* webpackChunkName: "component---src-pages-wazirx-clone-script-js" */),
  "component---src-pages-white-label-crypto-exchange-software-js": () => import("./../../../src/pages/white-label-crypto-exchange-software.js" /* webpackChunkName: "component---src-pages-white-label-crypto-exchange-software-js" */),
  "component---src-pages-white-label-crypto-wallet-js": () => import("./../../../src/pages/white-label-crypto-wallet.js" /* webpackChunkName: "component---src-pages-white-label-crypto-wallet-js" */),
  "component---src-pages-white-label-nft-marketplace-js": () => import("./../../../src/pages/white-label-nft-marketplace.js" /* webpackChunkName: "component---src-pages-white-label-nft-marketplace-js" */)
}

